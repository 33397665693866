@import './tailwind.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    font-family: "Noto Sans", sans-serif;
  
  }